import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { defineAsyncComponent } from 'vue';

import { availabeRole } from '@/core/helpers/access';

import stateStore from "@/core/store/index";

import { checkBeforeCreating } from './api';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./Edit/Index.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./List/Index.vue'))
		},
		select: {
			panel: {
				width: '90%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					study: {},
					patient: {},
					notion: {
						config: {
							visible: false
						}
					}
				}
			}
		}
	},

	onBeforeDelete: async function (data: any) {
		if (availabeRole(['admin']) ? true : data.author_dep == stateStore.state.user.department) {
			return true;
		} else {
			Swal.fire('Внимание', 'У Вас недостаточно прав для удаления данного исследования!', 'warning');

			return false;
		}
	},

	onBeforeSave: async function (data: any, isNew: boolean) {
		if (isNew && data.patient && data.date_collect) {
			const response: any = await checkBeforeCreating({
				patient: data.patient,
				date_collect: data.date_collect
			});

			if (!response.complete) {
				const date_collect = data.date_collect ? `с датой забора ${dayjs(data.date_collect).format('DD.MM.YYYY')}` : '';

				const { value } = await Swal.fire({
					title: `Исследование пациента ${data._patient} ${date_collect} уже существует. Продолжить?`,
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				});

				return value;
			}
		}

		return true;
	}
})